.footer {
    background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0px;
    flex-direction: column;
    padding: 60px 20px;
    margin-top: 40px;
}
.menu {
    margin-bottom: 0;
}

.menu ul {
    display: flex;
    list-style: none;
    margin:0;
    padding:0;
}

.menu li {
    padding-right:10px;
    text-decoration: underline;
    font-size: 14px;
}

li a {
    color: var(--primary);
}
img {
    width: 64px;
}

.footer-b {
    background: #eeeeee;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px;
}

.image {
    margin-right: 20px;
    width: 32px;
}

.image img {
    width: 32px;
}

.footer-b-content {
    width: auto;
}

h4 {
    font-size: 12px;
    font-weight: 400;
}


@media screen and (max-width: 900px) {

    .menu ul {
        flex-direction: column;
        text-align: center;
    }


}
