.links {
    width: 100%;
    padding: 20px;
    background: var(--primary);
    color: #fff;
}
.links-header {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px 20px 10px;
    color: var(--primary);
}
a {
    color: var(--secondarydark);
    font-weight: bold;
    font-size: 1.2rem;
}

li {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

@media screen and (max-width: 900px) {
    .links-header {
        padding: 20px 0 10px;
    }

    a {
        color: var(--secondarydark);
        font-weight: bold;
        font-size: 1rem;
    }

    li {
        font-size: 1rem;
        line-height: 1.6rem;
    }

}

