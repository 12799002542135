::placeholder {
    color: var(--purple);
    font-weight: 200;
}

.titel {
    text-align: center;
    font-size: 1.4rem;
    padding: 0 130px;
}

form.subscribe {
    padding: 40px;
    background: var(--lightpink);
    max-width: 100%;
}
.row {
    display: flex;
    align-items: normal;
    justify-content: space-around;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    padding: 40px;
}

.row.text {
    font-size: 1.2em;
    color: #000;
}

.primary {
    color: var(--primary)
}

.subrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem auto;
    max-width: 800px;
}
.inputs {
    background: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.half {
    width: calc(50% - 10px);
}

.full {
    width: 100%;
}

form p {
    color: var(--purple);
}
form label {
    color: var(--purple);
}
form a {
    font-size: 16px;
    color: var(--purple);
    text-decoration: underline;
    font-weight: normal;
}
.textinput {
    border: 2px solid var(--purple);
    color: var(--purple);
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 20px;
    background: transparent;
    border-radius: 7px;
}

.textinput:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--secondary);
    outline: none;
}
.btnholder {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}

.submitbtn {
    background: var(--purple);
    border: none;
    color: var(--pink);
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 20px 40px;
    white-space: nowrap;
    margin-top:20px;
    border-radius: 7px;
}

.submitbtn:disabled {
    opacity: 0.5;
}

.submitbtn:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--secondary);
    outline: 1px solid var(--secondarydark);
}

.error {
    color: #a00;
    font-size: 1rem;
}

.success {
    color: #0a0;
    font-size: 1rem;
}

small {
    font-size: 0.8rem;
    font-weight: normal;
}

small a {
    color: #fff;
    font-size: 0.8rem;

}

h2 {
    margin-top: 0;
    font-size: 2.4rem;
    color: var(--purple)
}

.whitetext {
    color: #fff;
    font-size: 1.5em;
}


@media screen and (max-width: 900px) {
    .row {
        padding: 20px;
    }
    .pin-holder {
        top: 20px;
        transform: rotate(90deg) scale(0.75);
        left: calc(50% - 86px);
    }

    .subrow {
        flex-direction: column;

    }

    .titel {
        text-align: left;
        padding: 0;
        font-size: 1.1rem;
    }

    .title {
        font-size: 1.8em;
    }

    .whitetext {
        font-size: 1.2em;
    }


    .textinput {

        width: 100%;
    }

    .submitbtn {

        width: 100%;
    }

    form.subscribe {
        padding: 20px;
    }
}

