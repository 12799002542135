.cookiebtn {
    background: var(--secondarydark);
    border: 2px solid var(--secondarydark);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    padding: 0.9em;
    margin: 1em;
    text-transform: uppercase;
    white-space: nowrap;
}
button#rcc-decline-button {
    background: transparent;
    border: 2px solid;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    padding: 0.9em;
    margin: 1em;
    text-transform: uppercase;
    white-space: nowrap;
}

.form {
    background: transparent;
}

body {
    background: var(--lightbluebg);
    color: var(--primary);
}

.cookiebtn:disabled {
    opacity: 0.5;
}

p {
    margin-top: 0;
    line-height: 1.4em;
    font-size: 1.1em;
}
h1 {
    text-align: center;
    font-size: 3em;
}
.cookiebtn:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--secondary);
    outline: 1px solid var(--secondarydark);
}

.cookiecontainer {
    align-items: center;
    padding: 1em;
}

.cookiecontent h6 {
    font-size: 1.1rem;
    margin: 0 0 1rem;
}
.cookiecontent p {
    font-size: 0.9rem;
    margin: 0;
}



@media screen and (max-width: 900px) {

.row {
    padding: 20px !important;
}
    h1 {
        font-size:1.8em;
    }

}

