.header {
    background: transparent;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px;
}

.header-img {
    width:100%;
}

.offscreen{
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}


@media screen and (min-width: 900px) {

    .row.header-special {
        padding: 0;
    }

}
